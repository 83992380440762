<template>
  <mobile-footer
   id="ipost-mobilefooter"
    class="ipost-mobilefooter"
    :items="items"
  />
</template>

<script>
import dashboardIcon from '@/assets/ipost/dashboard.png'
import packageIcon from '@/assets/ipost/package.png'
import settingIcon from '@/assets/ipost/user.png'

const mobileFooterImages = [
  dashboardIcon,
  packageIcon,
  settingIcon
]

export default {
  components: {
    MobileFooter: () => import('../../main/components/MobileFooter')
  },
  computed: {
    items () {
      return this.company.MobileFooter.Menu.map((m, i) => {
          if (m.name === 'Profile') {
            const { room } = this.$store.state.socket
            if (room && room.Unseen) {
              m.badge = {
                type: 'is-danger',
                value: room.Unseen
              }
            }
          }
          return { ...m, img: mobileFooterImages[i], icon: undefined }
        })
    }
  }
}
</script>

<style>

</style>
